import React, { useEffect, useRef, useState } from "react";
import "../MutualFunds/style.scss";
import { ChevronDown, FileDownIcon, Loader } from "lucide-react";
import { useLocation } from "react-router-dom";
import {
  FUND_DOCUMENTS,
  INDX_KEYS,
  KEYSx,
  MAIN_TITLE_LOGO,
} from "../IFunds/IFunds";
import { downloadCSVFile } from "../../../src/Utils/helper";
import { Api } from "../../../src/Api";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  constants,
  getDeviceType,
  percentageDifference,
  removePLC,
} from "../../../src/lib/utils";
import { HoldingsComp } from "./comps/Holdings";
import { Fundamentals } from "./comps/Fundamentals";
import _ from "lodash";
import Overview from "./comps/ui/Overview";
import RiskComp from "./comps/ui/RiskComp";
import MarketStatus from "./comps/ui/MainHeader";
import StickyHeader, { Header2 } from "./comps/ui/StickyHeader";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import { InfoCircledIcon } from "@radix-ui/react-icons";

export function getMinValue(arr) {
  if (arr.length === 0) {
    return 0;
  }
  return Math.min(...arr);
}

export const calculatePercentageOfBar = (numerator, denominator) => {
  if (denominator === 0) return "0%";
  return (
    ((numerator / denominator) * 100 > 100
      ? 100
      : (numerator / denominator) * 100
    ).toFixed(0) + "%"
  );
};

export function getMaxValue(arr) {
  if (arr.length === 0) {
    return 0;
  }
  return Math.max(...arr);
}

export function calculatePercentChange(oldValue, newValue) {
  if (oldValue === 0) {
    return 0;
  }
  let percentChange = (Number(newValue) / Number(oldValue) - 1) * 100;

  return percentChange.toFixed(2); // returns the result rounded to 2 decimal places
}

export function calculateCumulativePercentChange(initialPrice, currentPrice) {
  return ((currentPrice - initialPrice) / initialPrice) * 100;
}

function Index() {
  const navMenu = [
    {
      name: "Overview",
    },
    {
      name: "Holdings",
    },
    {
      name: "Allocations",
    },

    {
      name: "Metrics",
    },
    {
      name: "Documents",
    },
  ];

  const [liveTickerPrices, setliveTickerPrices] = useState({});
  const [fadeInAni, setFadeInAni] = useState("fade");
  const [page, setpage] = useState("Overview");
  const [isScrolled, setIsScrolled] = useState(false);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const { pathname } = useLocation();

  let query = useQuery();
  let menu = Number(query.get("menu"));
  let source = query.get("source");

  useEffect(() => {
    if (source == "wl") {
      localStorage.setItem("isUser", true);
    }
  }, [source]);

  let KEYS = pathname == "/fund" ? KEYSx : INDX_KEYS;
  let isIndex = pathname == "/fund" ? false : true;

  const [livePrice, setlivePrice] = useState(0);
  const [prevlivePrice, setprevlivePrice] = useState(null);
  const [liveChanges, setliveChanges] = useState(null);
  const [calcRisk, setcalcRisk] = useState({});
  const [metrics, setmetrics] = useState({});
  const [portSum, setportSum] = useState(null);
  const [equitySum, setequitySum] = useState(null);
  const [lineChartData2, setLineChartData2] = useState([]);
  const [liveChartRaw, setLiveChartRaw] = useState([]);
  const [liveChart, setLiveChart] = useState({});
  const [fundDetails, setfundDetails] = useState();
  const [snapshot, setsnapshot] = useState(null);

  // useEffect(() => {
  //   const connectWebSocket = () => {
  //     socketRef.current = new WebSocket("ws://blackstargroup.ai");

  //     socketRef.current.onopen = () => {
  //       console.log("WebSocket connection established");
  //     };

  //     const throttleUpdate = _.throttle((newData) => {
  //       setliveTickerPrices({
  //         ...liveTickerPrices,
  //         ...newData?.tickers,
  //       });

  //       setprevlivePrice(Number(newData.prevPrice ?? 0));
  //       setlivePrice(Number(newData.price ?? 0));
  //     }, 1000);

  //     socketRef.current.onmessage = (event) => {
  //       // console.log(">>>>>>123", event);
  //       const newData = JSON.parse(event.data);
  //       if (!event?.data?.status) {
  //         throttleUpdate(newData);
  //       }
  //     };

  //     socketRef.current.onclose = () => {
  //       console.log("WebSocket connection closed");
  //     };

  //     socketRef.current.onerror = (error) => {
  //       console.error("WebSocket error:", error);
  //     };
  //   };

  //   if (isIndex && menu == 1) {
  //     connectWebSocket();
  //   }

  //   return () => {
  //     if (socketRef.current) {
  //       socketRef.current.close();
  //     }
  //   };
  // }, []);

  useEffect(() => {
    if (isIndex && menu == 1) {
      const fetchSnapshot = () => {
        Api.getSnapshot().then((response) => {
          setsnapshot(JSON.parse(response.data));
        });
      };

      fetchSnapshot(); // Initial call

      const intervalId = setInterval(fetchSnapshot, 2000);

      return () => clearInterval(intervalId);
    }
  }, [isIndex, menu]);

  useEffect(() => {
    if (snapshot) {
      setliveTickerPrices({
        ...liveTickerPrices,
        ...snapshot?.tickers,
      });

      setprevlivePrice(Number(snapshot?.prevPrice ?? 0));
      setlivePrice(Number(snapshot?.price ?? 0));
    }
  }, [snapshot]);

  useEffect(() => {
    // console.log(prevlivePrice);
    if (prevlivePrice === undefined || livePrice === undefined)
      return { change: 0, percentageChange: 0 };

    const change = livePrice - prevlivePrice;
    const percentageChange = (change / prevlivePrice) * 100 ?? 0;

    setliveChanges({ change, percentageChange });
  }, [livePrice]);

  useEffect(() => {
    if (menu) {
      Api.portfolioSummary(KEYS[menu - 1]).then((response) => {
        setportSum(response);
        // console.log(response);
      });

      Api.stockSummary(constants[KEYS[menu - 1]]).then((response) => {
        setequitySum(response);
        // console.log(response);
      });

      if (!isIndex)
        Api.fundsDetails(KEYS[menu - 1])
          .then((response) => {
            // console.log(response.data);
            setfundDetails(response?.data);
          })
          .catch((e) => {
            setfundDetails({});
          });

      Api.performanceChart(constants[KEYS[menu - 1]]).then((response) => {
        if (response) {
          if (response) {
            let rawResponse = response;
            let data = [];
            // let priceDataArr = [];

            rawResponse?.map((x, i) => {
              if (x?.marketQuoteTimestamp == x?.timestamp) {
                data?.push(x);
              }
            });

            setprevlivePrice(Number(data[data?.length - 1].unitPrice ?? 0));
            setLineChartData2(data);
          }
        }
        // console.log("???>>>", response);
      });

      Api.getMetrics(constants[KEYS[menu - 1]]).then((response) => {
        if (response) {
          setmetrics(response?.data);
        }
        // console.log("???>>>", response);
      });

      Api.getCalculatedRisks(KEYS[menu - 1]).then((response) => {
        if (response) {
          setcalcRisk(response);
        }
        // console.log("???>>>", response);
      });

      if (isIndex)
        Api.getLivePrices(KEYS[menu - 1]).then((response) => {
          setLiveChartRaw(response);
        });
    }
  }, [menu]);

  useEffect(() => {
    if (menu) {
      setInterval(() => {
        Api.getLivePrices(KEYS[menu - 1]).then((response) => {
          setLiveChartRaw(response);
        });
      }, 60000);
    }
  }, [menu]);

  useEffect(() => {
    setFadeInAni(fadeInAni === "fade" ? "fade2" : "fade");
  }, []);

  // STICKY HEADER
  useEffect(() => {
    const handleScroll = () => {
      const bigElement = document.getElementById("bigElement");
      if (bigElement) {
        const bigElementBottom = bigElement.getBoundingClientRect().bottom;
        setIsScrolled(bigElementBottom < 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (liveChartRaw?.length) {
      let data = [];

      liveChartRaw
        ?.sort((a, b) => a?.timeStamp - b?.timeStamp)
        ?.filter((x) => x?.price != 0)
        ?.filter((x) => x?.symbol == "GMSI")
        ?.map((x, i) => {
          data.push({
            timestamp: x?.timeStamp,
            price: x?.price,
            status: x?.status ?? "close",
          });
        });

      setLiveChart(data);
    }
  }, [liveChartRaw]);

  const [fullReturnData, setFullReturnData] = useState([]);
  const [priceData, setpriceData] = useState([]);
  const [lineChart, setLineChart] = useState([]);

  useEffect(() => {
    Api.performanceChart(constants[KEYS[menu - 1]]).then((response) => {
      if (response) {
        if (response) {
          let rawResponse = response;
          let data = [];
          let priceDataArr = [];
          let dataRetTemp = [];

          rawResponse?.map((x, i) => {
            if (x?.marketQuoteTimestamp == x?.timestamp) {
              priceDataArr.push(x?.unitPrice);
              data?.push({
                label: x?.marketQuoteTimestamp,
                value: x?.unitPrice,
                color: MAIN_TITLE_LOGO[isIndex ? 4 : menu]?.color,
                nav: x?.nav,
                aum: x?.aum,
              });

              dataRetTemp?.push({
                label: x?.marketQuoteTimestamp,
                price: x?.unitPrice,
                value:
                  i == 0
                    ? 0
                    : rawResponse[i - 1]?.unitPrice - rawResponse[i]?.unitPrice,
                color: MAIN_TITLE_LOGO[isIndex ? 4 : menu]?.color,
              });
            }
          });

          setLineChart(data);
          setpriceData(priceDataArr);
          setFullReturnData(dataRetTemp);
        }
      }
    });
  }, []);

  return (
    <React.Fragment>
      <div
        className={`bg-black relative mt-4 ${fadeInAni} flex text-white flex-col justify-start items-start text-left overflow-x-hidden`}
      >
        <StickyHeader
          isScrolled={isScrolled}
          isIndex={isIndex}
          equitySum={equitySum}
          livePrice={livePrice}
          lineChartData2={lineChartData2}
          fundDetails={fundDetails}
          portSum={portSum}
          menu={menu}
          liveChanges={
            liveChanges?.change
              ? liveChanges
              : {
                  change:
                    Number(
                      lineChartData2[lineChartData2?.length - 1]?.unitPrice
                    ) -
                    Number(
                      lineChartData2[lineChartData2?.length - 2]?.unitPrice
                    ),
                  percentageChange: percentageDifference(
                    Number(
                      lineChartData2[lineChartData2?.length - 1]?.unitPrice
                    ),
                    Number(
                      lineChartData2[lineChartData2?.length - 2]?.unitPrice
                    )
                  ),
                }
          }
          navMenu={navMenu}
          page={page}
          setpage={setpage}
          KEYS={KEYS}
          prevlivePrice={prevlivePrice}
        />

        <div id="bigElement" className="flex items-center fade-in-5">
          {equitySum?.logoPath ? (
            <img
              src={
                isIndex
                  ? "https://blackstar-prod-public-milan.s3.eu-south-1.amazonaws.com/" +
                    equitySum?.logoPath
                  : MAIN_TITLE_LOGO[menu]?.logo ||
                    "https://blackstar-prod-public-milan.s3.eu-south-1.amazonaws.com/" +
                      equitySum?.logoPath
              }
              className="w-14 h-14 mr-3 rounded-lg"
              style={{
                objectFit: "contain",
              }}
            />
          ) : (
            <div className="w-14 h-14 mr-3  rounded-lg bg-zinc-900 animate-pulse" />
          )}

          <div className="flex flex-col justify-start items-start fade-in-10 transition-all">
            <div className="flex gap-1 items-center justify-center">
              {equitySum?.name ? (
                isIndex ? (
                  <text className="text-xl md:text-3xl font-bold">
                    {equitySum?.name ?? ""}
                  </text>
                ) : (
                  <text className="text-xl md:text-3xl font-bold">
                    {removePLC(equitySum?.name) ?? ""}{" "}
                    <span className="text-lg text-zinc-500">PLC</span>
                  </text>
                )
              ) : (
                <div className="animate-pulse rounded-lg w-[30vw] h-[25px] bg-zinc-900 mb-1 fade-in-5 fade-out-5" />
              )}
              {equitySum?.name && (
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <InfoCircledIcon className="mt-2.5 hover:text-yellow-500 cursor-pointer" />
                    </TooltipTrigger>
                    <TooltipContent className="bg-black cursor-pointer max-w-[50vw]">
                      <p>{equitySum?.description}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
            <text className="text-base text-gray-400">{KEYS[menu - 1]}</text>
          </div>
        </div>

        <MarketStatus
          isIndex={isIndex}
          liveChanges={
            liveChanges?.change
              ? liveChanges
              : {
                  change:
                    Number(
                      lineChartData2[lineChartData2?.length - 1]?.unitPrice
                    ) -
                    Number(
                      lineChartData2[lineChartData2?.length - 2]?.unitPrice
                    ),
                  percentageChange: percentageDifference(
                    Number(
                      lineChartData2[lineChartData2?.length - 1]?.unitPrice
                    ),
                    Number(
                      lineChartData2[lineChartData2?.length - 2]?.unitPrice
                    )
                  ),
                }
          }
          fundDetails={fundDetails}
          livePrice={livePrice}
          prevlivePrice={prevlivePrice}
          lineChartData2={lineChartData2}
          portSum={portSum}
          lineChart={lineChart}
        />
        <div className="mt-4" />

        <div className="w-full overflow-x-auto no-scrollbar">
          <Header2 data={navMenu} page={page} setpage={setpage} />
        </div>

        {page == "Overview" && (
          <div className="fade w-full">
            <div className="flex items-center w-full justify-between h-[1px] bg-[#58595b7d]" />
            <Overview
              liveChanges={liveChanges}
              equitySum={equitySum}
              livePrice={livePrice}
              portSum={portSum}
              metrics={metrics}
              fundDetails={fundDetails}
              liveChart={liveChart}
              lineChart={lineChart}
              fullReturnData={fullReturnData}
              priceData={priceData}
              prevlivePrice={prevlivePrice}
            />
          </div>
        )}

        {page == "Holdings" &&
          (lineChart[lineChart?.length - 5] && portSum ? (
            <HoldingsComp
              nav={Number(
                lineChart[lineChart?.length - 1]?.aum ??
                  lineChart[lineChart?.length - 2]?.aum ??
                  lineChart[lineChart?.length - 3]?.aum ??
                  lineChart[lineChart?.length - 4]?.aum ??
                  lineChart[lineChart?.length - 5]?.aum ??
                  portSum?.nav ??
                  equitySum?.nav
              )}
              isIndex={isIndex}
              liveTickerPrices={liveTickerPrices}
              portSum={portSum}
            />
          ) : (
            <div className=" w-full mt-4 animate-pulse">
              <div className="h-[20px] mb-4 w-[180px] p-3 px-4 flex rounded-xl col-span-1 bg-zinc-900" />
              <div className=" w-full  grid grid-cols-3 gap-4">
                <div className="h-[100px]  w-full p-3 px-4 flex rounded-xl col-span-1 bg-zinc-900" />
                <div className="h-[100px]  w-full p-3 px-4 flex rounded-xl col-span-1 bg-zinc-900" />
                <div className="h-[100px]  w-full p-3 px-4 flex rounded-xl col-span-1 bg-zinc-900" />
              </div>

              <div className="h-[300px] mt-4 w-full p-3 px-4 flex rounded-xl col-span-1 bg-zinc-900" />
            </div>
          ))}

        {page == "Allocations" && <Fundamentals portSum={portSum} />}
        {page == "Metrics" && <RiskComp calcRisk={calcRisk} />}
        {page == "Documents" && (
          <div className="fade w-full">
            <div className="flex items-center w-full justify-between h-[1px] bg-[#58595b7d] " />

            {isIndex ? (
              <div
                className={`w-full grid ${
                  getDeviceType() === "mobile" ? "grid-cols-1" : "grid-cols-3"
                } gap-4 `}
              >
                {[
                  {
                    name: isIndex ? "Index Particulars" : "Fund Particulars",
                    documents: [
                      {
                        report: "fund_particulars",
                        data: [
                          {
                            name: "FIAP Scheme Particular",
                            // filePath:
                            //   "https://drive.google.com/file/d/1090sxl-Xq2DtCiMdyi3G992k0TltgwmZ/view?usp=drive_link",
                          },
                        ],
                      },
                    ],
                  },
                ]?.map((z, i) => {
                  return <DocSheet z={z} i={i} menu={menu} KEYS={KEYS} />;
                })}
              </div>
            ) : (
              <div
                className={`w-full grid ${
                  getDeviceType() === "mobile" ? "grid-cols-1" : "grid-cols-3"
                } gap-4 `}
              >
                {FUND_DOCUMENTS?.map((z, i) => {
                  return <DocSheet z={z} i={i} menu={menu} KEYS={KEYS} />;
                })}
              </div>
            )}
          </div>
        )}
        <div className="mt-40" />
      </div>
    </React.Fragment>
  );
}

export default Index;

// Start growing your funds today
const DocSheet = ({ z, menu, i, KEYS }) => {
  const [year, setYear] = useState("2024");
  const [year1, setYear1] = useState("2023");
  const [loading, setloading] = useState(false);
  const [loading1, setloading1] = useState(false);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const { pathname } = useLocation();

  // let query = useQuery();
  // let menu = Number(query.get("menu"));

  // let KEYS = pathname == "/fund" ? KEYSx : INDX_KEYS;
  let isIndex = pathname == "/fund" ? false : true;

  return (
    <div className="transition-all ease-in-out w-full min-h-fit mt-4 col-span-1 p-3 px-4 flex flex-col justify-start items-start rounded-xl bg-[#35353538]">
      <div className="fade w-full flex justify-between items-center transition-all ease-in-out">
        <text className="text-base font-bold">{z?.name}</text>

        {z?.documents[0].report === "fact_sheets" && (
          <DropdownMenu>
            <DropdownMenuTrigger className="w-fit justify-between bg-zinc-900 rounded-full px-3 py-1 text-white flex items-center text-base">
              {year}
              <ChevronDown size={20} className="mt-0.5 ml-2.5" />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="bg-black p-1 text-white min-w-[100px] transition-all ease-in-out fade">
              {z?.documents[0]?.data[0]?.yearWise?.map((y) => {
                return (
                  <DropdownMenuLabel
                    onClick={() => {
                      setYear(y?.year);
                    }}
                    className="cursor-pointer py-1 hover:bg-zinc-900 rounded-lg"
                  >
                    {y?.year}
                  </DropdownMenuLabel>
                );
              })}
            </DropdownMenuContent>
          </DropdownMenu>
        )}

        {z?.documents[0].report === "annual" && (
          <DropdownMenu>
            <DropdownMenuTrigger className="w-fit justify-between bg-zinc-900 rounded-full px-3 py-1 text-white flex items-center text-base">
              {year1}
              <ChevronDown size={20} className="mt-0.5 ml-2.5" />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="bg-black p-1 text-white min-w-[100px] transition-all ease-in-out fade">
              {z?.documents[0]?.data[0]?.years?.map((y) => {
                return (
                  <DropdownMenuLabel
                    onClick={() => {
                      setYear1(y?.year);
                    }}
                    className="cursor-pointer py-1 hover:bg-zinc-900 rounded-lg"
                  >
                    {y?.year}
                  </DropdownMenuLabel>
                );
              })}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>

      {z?.documents[0].report === "fact_sheets" &&
        z?.documents[0]?.data[menu - 1]?.yearWise
          ?.filter((f, c) => year == f?.year)[0]
          ?.monthWise?.map((x, i) => {
            return (
              <div
                className={`w-full flex justify-between items-center ${
                  i < 4 ? "border-b border-b-[#58595b7d]" : ""
                } py-3 transition-all ease-in-out`}
              >
                <text>{x?.month}</text>

                <a
                  className="align-self-center cursor"
                  href={x?.filePath}
                  target="_blank"
                >
                  <FileDownIcon className="cursor-pointer" />
                </a>
              </div>
            );
          })}

      {z?.documents[0].report === "annual" &&
        z?.documents[0]?.data[menu - 1]?.years
          ?.filter((f, c) => year1 == f?.year)[0]
          ?.categeoryWise?.map((x, i) => {
            return (
              <div
                className={`w-full flex justify-between items-center ${
                  i < 4 ? "border-b border-b-[#58595b7d]" : ""
                } py-3 transition-all ease-in-out`}
              >
                <text>{x?.category}</text>

                <a
                  className="align-self-center cursor"
                  href={x?.filePath}
                  target="_blank"
                >
                  <FileDownIcon className="cursor-pointer" />
                </a>
              </div>
            );
          })}

      {z?.documents[0].report === "fund_particulars" && (
        <div
          className={`w-full flex justify-between items-center ${
            i < 4 ? "border-b border-b-[#58595b7d]" : ""
          } py-3 transition-all ease-in-out`}
        >
          <text>{isIndex ? "Methodology" : "Scheme Particulars"}</text>

          <div
            className="align-self-center cursor"
            onClick={() => {
              setloading(true);
              Api.downloadFundParticular(constants[KEYS[menu - 1]]).finally(
                () => {
                  setloading(false);
                }
              );
            }}
            target="_blank"
          >
            {loading ? (
              <Loader className="animate-spin" />
            ) : (
              <FileDownIcon className="cursor-pointer hover:text-blue-500" />
            )}
          </div>
        </div>
      )}

      {z?.documents[0].report === "price_history" && (
        <div
          className={`w-full flex justify-between items-center ${
            i < 4 ? "border-b border-b-[#58595b7d]" : ""
          } py-3 transition-all ease-in-out`}
        >
          <text>1 Year History</text>
          {loading1 ? (
            <Loader className="animate-spin" />
          ) : (
            <FileDownIcon
              className="cursor-pointer hover:text-blue-500"
              onClick={() => {
                setloading1(true);
                Api.performanceExcel(
                  KEYS[menu - 1] == "DELTA" ? "PLC" : KEYS[menu - 1]
                )
                  .then((response) => {
                    downloadCSVFile(
                      response?.data?.data,
                      response?.data?.meta?.file_name
                    );
                  })
                  .catch((err) => {
                    console.log(err);
                  })
                  .finally(() => {
                    setloading1(false);
                  });
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
