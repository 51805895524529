import React, { useEffect, useRef, useState } from "react";
import "../styles/page1.scss";
import { login, register } from "../api/api";
import axiosInstance, { BASE_API } from "../api/axios";
import axios from "axios";

function Register({ setPage, page }) {
  const [fadeInAni, setFadeInAni] = useState("fade");
  const [loading, setLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    cpassword: "",
  });

  const [userAuth, setuserAuth] = useState({
    email: "",
    password: "",
  });
  const [otp, setotp] = React.useState({
    otp: "",
    referenceID: "",
  });

  const [error, setError] = useState(null);
  const [stage, setstage] = useState(null);

  useEffect(() => {
    setFadeInAni(fadeInAni === "fade" ? "fade2" : "fade");
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error]);

  const handleSignup = async () => {
    if (user.password != user.cpassword) {
      alert("Passwords don't match");
    }
    if (!user.firstName || !user.lastName || !user.email || !user.password) {
      //   alert("Please fill all the fields");
      setError("Please fill all the fields");
      return;
    }

    setLoading(true);
    let res = await register({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      password: user.password,
    });

    if (res) {
      setstage("confirm");
    } else {
      setError("Something went wrong, Please try again");
    }
    setLoading(false);
  };

  if (stage === "confirm") {
    return (
      <React.Fragment>
        <main className={`init_page ${fadeInAni}`}>
          <div className="register_container">
            <div
              className=""
              style={{
                width: "100%",
              }}
            >
              <h1>Please confirm your email</h1>
              <h2>
                We have sent you an email, check your inbox and click on the
                link to confirm your email
              </h2>
            </div>
            <div className="spacer-h-3" />

            <div
              style={{
                justifyContent: "flex-start",
              }}
              className="button_container reg_btn"
            >
              <button
                style={{ margin: 0 }}
                onClick={() => setstage("login")}
                className="btn btn-primary"
              >
                {loading ? "Loading" : "Continue"}
              </button>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }

  if (stage === "login") {
    return (
      <React.Fragment>
        <main className={`init_page ${fadeInAni}`}>
          <div className="register_container">
            <div
              className=""
              style={{
                width: "100%",
              }}
            >
              <div className="goback" onClick={() => setstage("")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-move-left"
                >
                  <path d="M6 8L2 12L6 16" />
                  <path d="M2 12H22" />
                </svg>
                Go Back
              </div>
              <h1>Login</h1>
              <h2>Enter your email and password to login</h2>
            </div>
            <InputGroup
              label="Email"
              type="text"
              value={userAuth?.email}
              onChange={(e) =>
                setuserAuth({
                  ...userAuth,
                  email: e.target.value,
                })
              }
            />
            <InputGroup
              label="Password"
              type="password"
              value={userAuth?.password}
              onChange={(e) =>
                setuserAuth({
                  ...userAuth,
                  password: e.target.value,
                })
              }
            />
            <div className="button_container reg_btn">
              <button
                onClick={async () => {
                  setIsLoading2(true);
                  await axios
                    .post(BASE_API + "/public/user/login", {
                      ...userAuth,
                    })
                    .then((response) => {
                      if (response.data?.data) {
                        setotp({
                          ...otp,
                          referenceID: response.data?.data,
                        });
                        setstage("otp");
                      }
                      setIsLoading2(false);
                    })
                    .catch((e) => {
                      console.log(e);
                      setIsLoading2(false);
                      setError("Something went wrong.");
                    });
                }}
                className="btn btn-primary"
              >
                {isLoading2 ? "Loading" : "Sign In"}
              </button>
            </div>

            <h2
              className={fadeInAni}
              style={{
                color: "red",
                textAlign: "right",
                marginTop: "10px",
              }}
            >
              {error}
            </h2>
          </div>
        </main>
      </React.Fragment>
    );
  }

  if (stage === "otp") {
    return (
      <React.Fragment>
        <main className={`init_page ${fadeInAni}`}>
          <div className="register_container">
            <div
              className=""
              style={{
                width: "100%",
              }}
            >
              <div className="goback" onClick={() => setstage("login")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-move-left"
                >
                  <path d="M6 8L2 12L6 16" />
                  <path d="M2 12H22" />
                </svg>
                Go Back
              </div>
              <h1>OTP Verification</h1>
              <h2>We have sent you about 6 digit OTP on your email</h2>
            </div>
            <OTPInput
              length={6}
              value={otp.otp}
              onChange={(e) =>
                setotp({
                  ...otp,
                  otp: e,
                })
              }
            />

            <div className="button_container reg_btn">
              <button
                onClick={async () => {
                  setIsLoading(true);
                  if (otp.otp.length == 6) {
                    const r = await login({ ...otp, otp: otp.otp });

                    if (r == "error") {
                      setError("Something went wrong.");
                    }

                    setIsLoading(false);
                  }
                }}
                className="btn btn-primary"
              >
                {isLoading ? "Loading" : "Submit"}
              </button>
            </div>

            <h2
              className={fadeInAni}
              style={{
                color: "red",
                textAlign: "right",
                marginTop: "10px",
              }}
            >
              {error}
            </h2>
          </div>
        </main>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <main className={`init_page ${fadeInAni}`}>
        <div className="register_container">
          <div
            className=""
            style={{
              width: "100%",
            }}
          >
            <div className="goback" onClick={() => setPage(page - 1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-move-left"
              >
                <path d="M6 8L2 12L6 16" />
                <path d="M2 12H22" />
              </svg>
              Go Back
            </div>

            <h1>Create an account</h1>
            <h2>
              Create an {page === 3 ? "individual" : "institutional"} account to
              access the KYC form
            </h2>
          </div>
          <div className="spacer-h-3" />
          <div className="row">
            <InputGroup
              label="First Name"
              type="text"
              value={user?.firstName}
              onChange={(e) =>
                setUser({
                  ...user,
                  firstName: e.target.value,
                })
              }
            />
            <InputGroup
              label="Last Name"
              type="text"
              value={user?.lastName}
              onChange={(e) =>
                setUser({
                  ...user,
                  lastName: e.target.value,
                })
              }
            />
          </div>
          <InputGroup
            label="Email"
            type="text"
            value={user?.email}
            onChange={(e) =>
              setUser({
                ...user,
                email: e.target.value,
              })
            }
          />
          <div className="row">
            <InputGroup
              label="Password"
              type="password"
              value={user?.password}
              onChange={(e) =>
                setUser({
                  ...user,
                  password: e.target.value,
                })
              }
            />
            <InputGroup
              label="Confirm Password"
              type="password"
              value={user?.cpassword}
              onChange={(e) =>
                setUser({
                  ...user,
                  cpassword: e.target.value,
                })
              }
            />
          </div>
          <h2
            className={fadeInAni}
            style={{
              color: "red",
              textAlign: "left",
              marginTop: "10px",
            }}
          >
            {error}
          </h2>
          <div className="button_container reg_btn">
            <button onClick={handleSignup} className="btn btn-primary">
              {loading ? "Loading" : "Sign Up"}
            </button>
          </div>

          {/* <h2
            className={fadeInAni}
            style={{
              color: "red",
              textAlign: "right",
              marginTop: "10px",
            }}
          >
            {error}
          </h2> */}
          <h2
            onClick={() => setstage("login")}
            className={fadeInAni}
            style={{
              color: "#f4bd12",
              textAlign: "center",
              marginTop: "20px",
              cursor: "pointer",
            }}
          >
            <span
              style={{
                color: "#c1c1c1",
              }}
            >
              Already have an account?
            </span>{" "}
            Login
          </h2>
        </div>
      </main>
    </React.Fragment>
  );
}

export default Register;

const InputGroup = ({
  label = "",
  placeholder = "",
  value,
  onChange,
  type,
}) => {
  return (
    <div className="input-contain">
      <label>{label}</label>
      <input
        value={value}
        onChange={onChange}
        type={type}
        className="input-field"
        placeholder={placeholder}
      />
    </div>
  );
};

const OTPInput = ({ length, onChange, value }) => {
  const [otpArray, setOtpArray] = useState(Array(length).fill(""));
  const inputRefs = useRef([]);

  useEffect(() => {
    setOtpArray(value.split("").concat(Array(length - value.length).fill("")));
  }, [value, length]);

  const handleChange = (e, index) => {
    const newOtpArray = [...otpArray];
    newOtpArray[index] = e.target.value;

    if (e.target.value.length === 1 && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }

    setOtpArray(newOtpArray);
    onChange(newOtpArray.join(""));
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otpArray[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData
      .getData("text")
      .split("")
      .slice(0, length);
    setOtpArray(pasteData.concat(Array(length - pasteData.length).fill("")));
    onChange(pasteData.join(""));
  };

  return (
    <div className="otp-input" onPaste={handlePaste}>
      {otpArray.map((digit, index) => (
        <input
          key={index}
          type="number"
          maxLength="1"
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputRefs.current[index] = el)}
          className="otp-box"
          style={{ margin: "0 0.25em", textAlign: "center" }}
        />
      ))}
    </div>
  );
};
