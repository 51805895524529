import React, { useCallback, useEffect, useRef, useState } from "react";
import "../create_account.scss";
import axiosInstance, { BASE_API } from "../api/axios";
import { countryList, countryListFlag } from "../api/api";
import axios from "axios";

function InstitutionPage({ setPage }) {
  const [fadeInAni, setFadeInAni] = useState("fade");
  const [kyc, setKyc] = useState([]);
  const [userkyc, setuserKyc] = useState({});
  const [selectedSection, setselectedSection] = useState(0);
  const [selectedSubMenu, setSubMenu] = useState(1);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [showDisclaimer, setshowDisclaimer] = useState(false);
  const [subSectionPage, setsubSectionPage] = useState(0);
  const [formData, setFormData] = useState({});

  const handleChange = (questionId, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [questionId]: value,
    }));
  };

  const handleKycAdd = (e) => {
    e.preventDefault();

    let user = JSON.parse(localStorage.getItem("officer"));
    let data = {
      questionAnswer: formData,
      email: user?.email,
      phone: "string",
      clientSubTypeId: "68f93fa1-d5e7-4368-b60a-d82ba7a77510",
    };
    console.log(data);
    if (loading) {
      return;
    }
    setLoading(true);
    axiosInstance
      .post(BASE_API + "/kyc/add", data)
      .then((res) => {
        console.log(res.data);
        if (
          subSectionPage <
          kyc[selectedSection]?.kycFormSubSections.length - 1
        ) {
          setsubSectionPage(subSectionPage + 1);
        } else {
          if (selectedSection == kyc.length - 1) {
            setshowDisclaimer(true);
          } else {
            setselectedSection(selectedSection + 1);
            setsubSectionPage(0);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleKycAddLogout = () => {
    let user = JSON.parse(localStorage.getItem("officer"));
    let data = {
      questionAnswer: formData,
      email: user?.email,
      phone: "string",
      clientSubTypeId: "68f93fa1-d5e7-4368-b60a-d82ba7a77510",
    };
    console.log(data);
    if (loading) {
      return;
    }
    setLoading2(true);
    axiosInstance
      .post(BASE_API + "/kyc/add", data)
      .then((res) => {
        console.log(res.data);
        localStorage.clear();
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  useEffect(() => {
    setFadeInAni(fadeInAni === "fade" ? "fade2" : "fade");
  }, []);

  useEffect(() => {
    setLoadingPage(true);
    try {
      axiosInstance
        .get(BASE_API + "/kyc/68f93fa1-d5e7-4368-b60a-d82ba7a77510/get-kyc")
        .then((res) => {
          if (res.status === 200) {
            setKyc(res.data?.kycFormSections);
            setselectedSection(0);
            setSubMenu(
              res.data?.kycFormSections[selectedSection]?.kycFormSubSections[
                subSectionPage
              ]
            );
            try {
              axiosInstance.get(BASE_API + "/kyc/get").then((res2) => {
                if (res2.status === 200) {
                  let currentSection = res2.data?.currentSectionIdentifier;

                  if (currentSection) {
                    let ans = res.data?.kycFormSections.findIndex((x) => {
                      let subsection = x?.kycFormSubSections.filter((y) => {
                        return y?.identifier === currentSection;
                      });

                      return subsection.length > 0;
                    });

                    setselectedSection(ans);
                    setSubMenu(
                      res.data?.kycFormSections[ans]?.kycFormSubSections[
                        subSectionPage
                      ]
                    );
                  } else {
                    setselectedSection(0);
                    setSubMenu(
                      res.data?.kycFormSections[selectedSection]
                        ?.kycFormSubSections[subSectionPage]
                    );
                  }

                  console.log(res2.data);

                  setuserKyc(res2?.data);
                  setKyc(res.data?.kycFormSections);
                  console.log(">>>", res2.data);
                } else {
                  // console.log(res.data);
                  setKyc(res.data?.kycFormSections);
                  setselectedSection(0);
                  setSubMenu(
                    res.data?.kycFormSections[selectedSection]
                      ?.kycFormSubSections[subSectionPage]
                  );
                }

                setLoadingPage(false);
              });
            } catch (error) {
              console.log("error", error);
              setKyc(res.data?.kycFormSections);
              setselectedSection(0);
              setSubMenu(
                res.data?.kycFormSections[selectedSection]?.kycFormSubSections[
                  subSectionPage
                ]
              );
            }
          }
        })
        .catch((error) => {
          //
        })
        .finally(() => {
          setLoadingPage(false);
        });
    } catch (error) {
      //
    }
  }, []);

  useEffect(() => {
    setSubMenu(kyc[selectedSection]?.kycFormSubSections[subSectionPage]);
  }, [subSectionPage, selectedSection]);

  if (loadingPage) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#be9a2b"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="lucide lucide-loader-circle"
        >
          <path d="M21 12a9 9 0 1 1-6.219-8.56" />
        </svg>
      </div>
    );
  }

  if (userkyc?.kycStatus === "REJECTED") {
    return (
      <React.Fragment>
        <main className={`init_page ${fadeInAni}`}>
          <div className="register_container">
            <div
              className="submited"
              style={{
                width: "100%",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#c9302c"
                stroke-width="0.7"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-shield-x"
              >
                <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z" />
                <path d="m14.5 9.5-5 5" />
                <path d="m9.5 9.5 5 5" />
              </svg>

              <h1>KYC Rejected.</h1>
              <h2>Reason: {userkyc?.rejectionReason}</h2>
            </div>
            <div className="spacer-h-3" />

            <div className="left_container2">
              {kyc?.map((x, i) => {
                return (
                  <div
                    onClick={() => {}}
                    className={`left_container_item fade2`}
                  >
                    <div className={`image_container `}>
                      <div
                        className={`left_container_item_img ${
                          i <= selectedSection && "active"
                        }`}
                      >
                        {i < selectedSection ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="lucide lucide-check"
                          >
                            <path d="M20 6 9 17l-5-5" />
                          </svg>
                        ) : (
                          i + 1
                        )}
                      </div>
                      {i !== kyc?.length - 1 && (
                        <div
                          className={`line ${i < selectedSection && "active"}`}
                        />
                      )}
                    </div>

                    <div className="left_container_item_text">
                      <h1 className={`${i <= selectedSection && "active"}`}>
                        {x?.name}
                      </h1>
                      <p>{x?.description}</p>
                    </div>
                  </div>
                );
              })}
            </div>

            <div
              style={{
                justifyContent: "flex-start",
              }}
              className="button_container reg_btn"
            >
              <button
                style={{ marginRight: 20 }}
                onClick={() => {}}
                className="btn btn-primary"
              >
                Edit
              </button>
              <button
                style={{ margin: 0 }}
                onClick={() => {}}
                className="btn btn-ghost2"
              >
                Logout
              </button>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }

  if (userkyc?.kycStatus == "SUBMITTED") {
    return (
      <React.Fragment>
        <main className={`init_page ${fadeInAni}`}>
          <div className="register_container">
            <div
              className="submited"
              style={{
                width: "100%",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="0.7"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-shield-check"
              >
                <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z" />
                <path d="m9 12 2 2 4-4" />
              </svg>
              <h1>KYC Submitted.</h1>
              <h2>
                Your KYC is submitted and is Pending Verification. Kindly allow
                <br />
                for up to 72 hours for activation
              </h2>
            </div>
            <div className="spacer-h-3" />

            <div
              style={{
                justifyContent: "flex-start",
              }}
              className="button_container reg_btn"
            >
              <button
                style={{ margin: 0 }}
                onClick={() => {}}
                className="btn btn-ghost2"
              >
                Logout
              </button>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }

  if (userkyc?.kycStatus === "Verified") {
    return (
      <React.Fragment>
        <main className={`init_page ${fadeInAni}`}>
          <div className="register_container">
            <div
              className="submited"
              style={{
                width: "100%",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="0.7"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-shield-check"
              >
                <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z" />
                <path d="m9 12 2 2 4-4" />
              </svg>
              <h1>KYC Verified.</h1>
              <h2>Your KYC has been Verification.</h2>
            </div>
            <div className="spacer-h-3" />

            <div
              style={{
                justifyContent: "flex-start",
              }}
              className="button_container reg_btn"
            >
              <button
                style={{ margin: 0 }}
                onClick={() => {}}
                className="btn btn-ghost2"
              >
                Logout
              </button>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className={`${fadeInAni} main`}>
        <div className="main_container">
          <div className="left_container">
            {kyc?.map((x, i) => {
              return (
                <KYCSectionItem
                  kyc={kyc}
                  showLine={i !== kyc?.length - 1}
                  data={x}
                  activeLine={i < selectedSection}
                  active={i <= selectedSection}
                  index={i + 1}
                  onChange={() => {
                    setsubSectionPage(0);
                    setselectedSection(i);
                    setSubMenu(kyc[i]?.kycFormSubSections[subSectionPage]);
                    setshowDisclaimer(false);
                  }}
                />
              );
            })}
            <KYCLastSectionItem
              kyc={kyc}
              onClick={setshowDisclaimer}
              // showLine={i !== kyc?.length - 1}
              // data={x}
              // activeLine={i < selectedSection}
              // active={i <= selectedSection}
              // index={i + 1}
              // onChange={() => {
              //   setselectedSection(i);
              //   setSubMenu(kyc[i]?.kycFormSubSections[subSectionPage]);
              // }}
            />
          </div>

          {showDisclaimer ? (
            <div className={"right_container " + fadeInAni}>
              <Disclaimer kycid={userkyc?.id} userkyc={userkyc} />
            </div>
          ) : (
            <div className={"right_container fade"}>
              <SubSectionHeader
                setSubMenu={setSubMenu}
                selectedSubMenu={selectedSubMenu}
                data={kyc[selectedSection]?.kycFormSubSections}
                setsubSectionPage={setsubSectionPage}
              />
              <form
                onSubmit={handleKycAdd}
                id="fade"
                className={`sub_content ${fadeInAni}`}
              >
                <div className="spacer-h-3" />
                <div className="wrap fade">
                  {selectedSubMenu?.kycFormQuestions?.map((x, i) => {
                    return (
                      <SubSectionView
                        kycid={userkyc?.id}
                        id={selectedSubMenu}
                        x={x}
                        formData={formData}
                        handleChange={handleChange}
                        key={i}
                      />
                    );
                  })}
                </div>

                <div className="button_container2">
                  <button
                    disabled={loading2 || loading}
                    onClick={handleKycAddLogout}
                    className="btn btn-ghost"
                    type="submit"
                  >
                    {loading2 ? "Loading..." : "Save & Logout"}
                  </button>
                  <div className="spacer-w-3" />
                  <button
                    disabled={loading || loading2}
                    className="btn btn-primary"
                    type="submit"
                  >
                    {loading ? "Loading..." : "Save & Continue"}
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default InstitutionPage;

const KYCSectionItem = ({
  showLine,
  data,
  active,
  activeLine,
  index,
  onChange,
  kyc,
}) => {
  return (
    <div onClick={onChange} className={`left_container_item fade2`}>
      <div className="left_container_item_text">
        <h1 className={`${active && "active"}`}>{data?.name}</h1>
        <p>{data?.description}</p>
      </div>
      <div className={`image_container `}>
        <div className={`left_container_item_img ${active && "active"}`}>
          {activeLine ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-check"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ) : (
            index
          )}
        </div>
        {(showLine || index === kyc?.length) && (
          <div className={`line ${activeLine && "active"}`} />
        )}
      </div>
    </div>
  );
};

const KYCLastSectionItem = ({
  showLine,
  data,
  active,
  activeLine,
  index,
  onChange,
  kyc,
  onClick,
}) => {
  return (
    <div onClick={onClick} className={`left_container_item fade2`}>
      <div className="left_container_item_text">
        <h1 className={`${active && "active"}`}>Disclaimer</h1>
        <p>{data?.description}</p>
      </div>
      <div className={`image_container `}>
        {/* <div className={`line ${activeLine && "active"}`} /> */}
        <div className={`left_container_item_img ${active && "active"}`}>
          {activeLine ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-check"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ) : (
            kyc?.length + 1
          )}
        </div>
      </div>
    </div>
  );
};

export const FileInputGroup = ({
  label = "",
  placeholder = "",
  value,
  onChange,
  kycid,
  identifier,
  required,
  x,
  type,
}) => {
  const [loading, setLoading] = useState(false);
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    console.log(file);

    if (file) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("files[0].file", file);
        formData.append("files[0].identifier", identifier);

        const response = await axiosInstance.post(
          `${BASE_API}/kyc/${kycid}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          console.log("File uploaded successfully");
          console.log(response.data[file.name]);
          onChange(response?.data[file?.name]);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFileRemove = async () => {
    try {
      const response = await axiosInstance.delete(
        `${BASE_API}/kyc/${kycid}/document/${value}/delete`
      );
      if (response.status === 200) {
        console.log("File removed successfully");
        onChange(null);
      }
    } catch (error) {
      console.error("Error removing file:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="input-contain3">
      <label>
        {label} <span style={{ color: "Red" }}>{required && "*"}</span>
      </label>
      <span>
        {x?.description} ({type})
      </span>
      {value ? (
        <div className="file-container">
          <div style={{}}>
            <div className="file-name">File Uploaded</div>
            <div className="file-size">{value}</div>
          </div>
          <div className="file-remove">
            <button onClick={handleFileRemove}>Remove</button>
          </div>
        </div>
      ) : (
        <>
          <input
            id="file-input"
            onChange={handleFileChange}
            type="file"
            className="file-input"
            placeholder={placeholder}
            required={required}
          />
          <label htmlFor="file-input" className="custom-file-input">
            {loading ? "Uploading..." : "Choose File"}
          </label>
        </>
      )}
    </div>
  );
};

export const DateInputGroup = ({ label = "", value, onChange, required }) => {
  return (
    <div className="input-contain4">
      <label>
        {label} <span style={{ color: "Red" }}>{required && "*"}</span>
      </label>
      <input
        value={value}
        onChange={onChange}
        type="date"
        className="custom-date-input"
        required={required}
      />
    </div>
  );
};

export const InputGroup = ({
  label = "",
  placeholder = "",
  value,
  onChange,
  required,
}) => {
  return (
    <div className="input-contain2">
      <label>
        {label} <span style={{ color: "Red" }}>{required && "*"}</span>
      </label>
      <input
        value={value}
        onChange={onChange}
        type="text"
        className="input-field"
        placeholder={placeholder}
        required
      />
    </div>
  );
};

export const PhoneInputGroup = ({
  label = "",
  placeholder = "",
  onChange,
  required,
  value,
}) => {
  const [selectedCountry, setSelectedCountry] = useState("GH");
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [number, setnumber] = useState("");

  useEffect(() => {
    if (number) {
      onChange(
        countryList?.filter((x) => x.code == selectedCountry)[0]?.dial_code +
          "" +
          number
      );
    }
  }, [number]);

  useEffect(() => {
    if (selectedCountry) {
      document.getElementById("ccode").style.visibility = "hidden";
      document.getElementById("ccode").classList.remove("fade");
      document.getElementById("ccode1").style.visibility = "hidden";
      document.getElementById("ccode1").classList.remove("fade");
      setTimeout(() => {
        document.getElementById("ccode").style.visibility = "visible";
        document.getElementById("ccode").classList.add("fade");
        document.getElementById("ccode1").style.visibility = "visible";
        document.getElementById("ccode1").classList.add("fade");
      }, 100);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (!open) setQuery("");
  }, [open]);

  return (
    <div className="phone-input-contain3">
      <label>
        {label} <span style={{ color: "Red" }}>{required && "*"}</span>
      </label>
      <div className="input-group">
        <>
          <div
            onClick={() => {
              setOpen(!open);
            }}
            className="code2 w-5 h-5"
          >
            <img
              id="ccode"
              src={
                countryListFlag?.filter((x) => x.code === selectedCountry)[0]
                  ?.image
              }
              alt=""
              className="w-5 h-5"
            />
            <div id="ccode1">
              {countryList?.filter((x) => x.code === selectedCountry)[0]?.code}
            </div>
            <div
              style={{
                transform: "rotate(180deg)",
                marginLeft: "2px",
                marginTop: "2px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="#fff"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="arrow"
              >
                <path d="M6 10l5 5 5-5z" />
              </svg>
            </div>
          </div>

          <div
            style={{
              display: open ? "flex" : "none",
              borderRadius: 6,
            }}
            className="dropdown-container fade2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="#000"
              stroke="#58595b7d"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="arrow"
            >
              <path d="m18 15-6-6-6 6" />
            </svg>
            <div className="input-con">
              <input
                placeholder="Search country"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <section className="dropdown">
              {countryList
                ?.filter((x) =>
                  String(x?.name)
                    .toLocaleLowerCase()
                    .includes(String(query).toLocaleLowerCase())
                )
                ?.map((x, i) => {
                  return (
                    <div
                      onClick={() => {
                        setSelectedCountry(x?.code);
                        setOpen(!open);
                      }}
                    >
                      <img
                        src={
                          countryListFlag?.filter((y) => y.code === x?.code)[0]
                            ?.image
                        }
                        alt=""
                      />
                      <option key={i} value={x?.code}>
                        {x?.name + " (" + x?.code + ")"}
                      </option>
                    </div>
                  );
                })}
            </section>
          </div>
        </>

        <input
          className="codetext"
          value={`(${
            countryList?.filter((x) => x.code == selectedCountry)[0]?.dial_code
          })`}
          type="text"
          placeholder={placeholder}
          contentEditable={false}
        />
        <input
          className="phone"
          value={number}
          onChange={(x) => setnumber(x.target.value)}
          type="number"
          placeholder={placeholder}
          required={required}
        />
      </div>
    </div>
  );
};

export const SelectGroup = ({
  label = "",
  placeholder = "",
  value,
  onChange,
  data,
  required,
}) => {
  return (
    <div className="input-contain5">
      <label>
        {label}
        <span style={{ color: "Red" }}>{required && "*"}</span>
      </label>
      <select
        required={required}
        value={value}
        onChange={onChange}
        className="custom-select-input"
      >
        {data?.optionValues?.map((x, i) => (
          <option key={i} value={x?.optionValue}>
            {x?.optionValue}
          </option>
        ))}
      </select>
    </div>
  );
};

const SubSectionHeader = ({
  setSubMenu,
  selectedSubMenu,
  data,
  setsubSectionPage,
}) => {
  return (
    <div className="wrapper-sub-menu">
      <div className="main-sub-menu-tab">
        {data?.map((x, i) => (
          <div
            className={`sub-tab ${selectedSubMenu == x && "active"}`}
            style={{
              borderBottom: selectedSubMenu == x ? `0.5px solid #f4bd12` : "",
            }}
            onClick={() => {
              // setSubMenu(x);
              setsubSectionPage(i);
            }}
          >
            <p className="my-1">{x?.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const SubSectionView = ({ x, formData, handleChange, id, kycid }) => {
  useEffect(() => {
    if (id?.id) {
      axiosInstance
        .get(
          BASE_API + "/kyc/" + kycid + "/" + id?.identifier + "/answer/get",
          {
            headers: {},
          }
        )
        .then((res) => {
          if (res.status === 200) {
            let savedAnswers = res?.data;
            savedAnswers?.map((x) => {
              handleChange(x?.questionIdentifier, x?.answer);
            });
          }
        })
        .catch((e) => {
          // console.log("<<<||", e);
        });
    }
  }, [id, kycid]);

  return (
    <>
      {["NUMERIC", "SINGLE_LINE"]?.includes(x?.questionType) ? (
        <InputGroup
          label={x?.question}
          placeholder={x?.description}
          data={x}
          value={formData[x?.identifier] || ""}
          onChange={(e) => handleChange(x?.identifier, e.target.value)}
          required={x?.required}
        />
      ) : x?.questionType == "SINGLE_CHOICE" ? (
        <SelectGroup
          label={x?.question}
          placeholder={x?.description}
          data={x}
          value={formData[x?.identifier] || ""}
          onChange={(e) => handleChange(x?.identifier, e.target.value)}
          required={x?.required}
        />
      ) : x?.questionType == "PHONE_NUMBER" ? (
        <PhoneInputGroup
          label={x?.question}
          placeholder={"24 4567 345"}
          data={x}
          value={formData[x?.identifier] || ""}
          onChange={(e) => handleChange(x?.identifier, e)}
          required={x?.required}
        />
      ) : x?.questionType == "DATE" ? (
        <DateInputGroup
          label={x?.question}
          placeholder={"24 4567 345"}
          data={x}
          value={formData[x?.identifier] || ""}
          onChange={(e) => handleChange(x?.identifier, e.target.value)}
          required={x?.required}
        />
      ) : ["FRONT_PHOTO", "BACK_PHOTO", "PHOTO", "DOCUMENT", "FILE"]?.includes(
          x?.questionType
        ) ? (
        <FileInputGroup
          x={x}
          type={x?.questionType}
          label={x?.question}
          placeholder={"24 4567 345"}
          data={x}
          value={formData[x?.identifier] || ""}
          onChange={(e) => handleChange(x?.identifier, e)}
          kycid={kycid}
          identifier={x?.identifier}
          required={x?.required}
        />
      ) : (
        <InputGroup
          label={x?.question}
          placeholder={x?.description}
          data={x}
          value={formData[x?.identifier] || ""}
          onChange={(e) => handleChange(x?.identifier, e.target.value)}
          required={x?.required}
        />
      )}
    </>
  );
};

const Disclaimer = ({ kycid, userkyc }) => {
  const [enable, setEnable] = useState(false);
  const [loading, setloading] = useState(false);
  const scrollRef = useRef(null);

  let answers = userkyc?.userKYCAnswerList;

  const handleScroll = useCallback(() => {
    const element = scrollRef.current;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setEnable(true);
      console.log("Scrolled to the end");
      // You can trigger any function or state update here
    }
  }, []);

  useEffect(() => {
    const element = scrollRef.current;
    element.addEventListener("scroll", handleScroll);

    return () => {
      element.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleSubmit = (e) => {
    // e.preventDefault();
    setloading(true);
    axios
      .post(
        "http://localhost:81/api/sendZoho",
        {
          templates: {
            field_data: {
              field_text_data: {
                "Business Name:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "4d4cc62861074484b34d661ae48c28c0"
                  )[0]?.answer ?? "",
                "Date:": new Date().toLocaleDateString(),
                "Industry:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "efa1d2fa939248068ed64677106601a9"
                  )[0]?.answer ?? "",
                "Category of Business:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "55b51e0e7a634d42a1ce8b47908d0c8b"
                  )[0]?.answer ?? "",
                "Coporate Email Address:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "b1bcf0e5ff1e4431a4e96dfa4699d772"
                  )[0]?.answer ?? "",
                "Corporate Website:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "49ffa09d610b4557b67f0e1360c91aae"
                  )[0]?.answer ?? "",
                "Digial Address (Ghana Post GPS):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "7ba6aec43d534f959dfca5257495da17"
                  )[0]?.answer ?? "",
                "Country:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "8c3ea3ee930c4b5289e22d9c5707062a"
                  )[0]?.answer ?? "",
                "Company Phone Number 2:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "f5219fe9930e4996a85c102dd435f339"
                  )[0]?.answer ?? "",
                "Company Phone Number 1:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "700a1ea307fa4790841296f52b42bbd9"
                  )[0]?.answer ?? "",
                "Certificate ofincorporation Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "f39711dc74ee4ba481dfd66a89026da9"
                  )[0]?.answer ?? "",
                "Date of incorporation:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "e93087e41b2c420dbf10e43f456b9ea9"
                  )[0]?.answer ?? "",
                "TIN/Ghana Card Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "4281a743842740a099440d99d0bcc864"
                  )[0]?.answer ?? "",
                "Source of Funds:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "b7ba67994e1b42759f14d90388e527b8"
                  )[0]?.answer ?? "",
                "Expected initial invesment (GHS:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "ec19ab20e3934287a43707eef68a9f4b"
                  )[0]?.answer ?? "",
                "Expected Top-Ups:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "eb14b36370e0440a821600574a7d6e24"
                  )[0]?.answer ?? "",
                "Expected withdrawals:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "e4c3958c8f444cb8b2d64a729c7bbc4a"
                  )[0]?.answer ?? "",
                "Contact Person First Name(s):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "e2b345c92edf44f3bd0d2fb68e20dc60"
                  )[0]?.answer ?? "",
                "Contact Person  Surname:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "41ea96d3c8e641fbb99ffb7b293359bd"
                  )[0]?.answer ?? "",
                "Contact Person  Date of Birth:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "2bef8a05c0d04b90a4275f1b2a9a42a0"
                  )[0]?.answer ?? "",
                "Contact Person  Ghana Card Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "26ae92072cd443b7aa920ed4f69415a6"
                  )[0]?.answer ?? "",
                "Contact Person Contact Number 1:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "0a2846128da34af3a447d330caffeb95"
                  )[0]?.answer ?? "",
                "Contact Person Contact Number 2:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "e446662d33a74e9987d40f4e0a8daf25"
                  )[0]?.answer ?? "",
                "Contact Person  Residential Status:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "4b699be35ffa4ec68a52ea2976a26bf5"
                  )[0]?.answer ?? "",
                "Contact Person Resident Permit Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "d353cc88858e448b9611a28b7d376e69"
                  )[0]?.answer ?? "",
                "Contact Person Job Title:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "09ab5093ad1e40c487017f3379aaa775"
                  )[0]?.answer ?? "",
                "Bank Name":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "f933d6afc1704bdaa46dfd27ca6e250e"
                  )[0]?.answer ?? "",
                "Account Name:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "0acfd716e3a243bb9a9c735c0c293e90"
                  )[0]?.answer ?? "",
                "Bank Sort Code:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "2a8f30a05680407e81320a20217c8bec"
                  )[0]?.answer ?? "",
                "Account Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "adaa30db9eb7435990b2c82862e9ae06"
                  )[0]?.answer ?? "",
                "Director 1 First Name(s):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "ec921cd2b8a14d61b2d4bd3269562cd2"
                  )[0]?.answer ?? "",
                "Director 1 Surname:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "3686a89a6f6f498e907630d18d3351e4"
                  )[0]?.answer ?? "",
                "Director 1 Ghana Card Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "22d91821cb3a4dc8baec4186f3c26266"
                  )[0]?.answer ?? "",
                "Director 1: IsThis Director Politically Exposed:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "429b5b7ab54842718e69e569017c49dd"
                  )[0]?.answer ?? "",
                "Director 1 Contact Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "ef6938225c724a0ab8aa8c7c972fdf7c"
                  )[0]?.answer ?? "",
                "Director 1 Passport-Sized Photo":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "0cfd7149545444928589ee2e9d6b5e6e"
                  )[0]?.answer ?? "",
                "Director 1 Ghana Card (Back):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "96cb719fe0bd47a1831d27266134013f"
                  )[0]?.answer ?? "",
                "Director 1 Ghana Card (Front):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "5be4f65b3afc4c998fb72cbe413ce283"
                  )[0]?.answer ?? "",
                //
                "Director 2 First Name(s):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "4580519cdf764fd89754344fc47bf836"
                  )[0]?.answer ?? "",
                "Director 2  Surname:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "da16046f679445048f7dfd2d187ca7e7"
                  )[0]?.answer ?? "",
                "Director 2 : IsThis Director Politically Exposed:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "b38335dae2184b43a433824687edb78a"
                  )[0]?.answer ?? "",
                "Director 2  Ghana Card Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "a84a4916f622449fb2ef62f2d2c1a19b"
                  )[0]?.answer ?? "",
                "Director 2  Contact Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "7f7bdca1881e43288961d06d57ec2d8b"
                  )[0]?.answer ?? "",
                "Director 2 Passport-Sized Photo":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "75e10352975046dc9aa89117f2b7eca7"
                  )[0]?.answer ?? "",
                "Director 2  Ghana Card (Back):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "82de93854f7e4335b59deed67a013b8c"
                  )[0]?.answer ?? "",
                "Director 2  Ghana Card (Front:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "b129d7b85fbe417c91ea102f14830217"
                  )[0]?.answer ?? "",
                //
                "Director 3 First Name(s):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "0f3341d273ee4df097676441240187a3"
                  )[0]?.answer ?? "",
                "Director 3 Surname:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "158e8fa286a5427a83dbc82c570cd884"
                  )[0]?.answer ?? "",
                "Director 3 Ghana Card Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "e609a446d4244387991a28882feddef6"
                  )[0]?.answer ?? "",
                "Director 3: IsThis Director Politically Exposed:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "aacf99d6f3d94a30a1880dfbebfc8726"
                  )[0]?.answer ?? "",
                "Director 3 Contact Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "05335d7ea229457aa64c1469fee43af2"
                  )[0]?.answer ?? "",
                "Director 3 Passport-Sized Photo":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "a1a1204e835c4cecb5b817c0a8a3c919"
                  )[0]?.answer ?? "",
                "Director 3 Ghana Card (Front):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "a9413434e8d84d59862528b4cb890ce2"
                  )[0]?.answer ?? "",
                "Director 3 Ghana Card (Back):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "d4986861b04645aaa1d624005b4588c3"
                  )[0]?.answer ?? "",
                //
                "BO First Name(s):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "1972eb69465d4548b732f133d8889bbb"
                  )[0]?.answer ?? "",
                "BO  Surname:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "1ddc527251b64eb9b08619cfddf11975"
                  )[0]?.answer ?? "",
                "BO  Date Of Birth:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "104393e53f09434ea81630f76860e59f"
                  )[0]?.answer ?? "",
                "BO  Ghana Card Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "2370d0b0f47449a696f247be436ddfb3"
                  )[0]?.answer ?? "",
                //
                "BO2 First Name(s):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "3a67ce53d5394240b06c5c740fcc50a2"
                  )[0]?.answer ?? "",
                "BO2 Surname:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "91ad67d4705b4cec90a19af1e05b281a"
                  )[0]?.answer ?? "",
                "BO2 Ghana Card Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "0ff1f6c22f3a4b849709650bcdff0107"
                  )[0]?.answer ?? "",
                "BO2 Date Of Birth:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "12153549a0b749f6961379d7e138559b"
                  )[0]?.answer ?? "",
                //
                "AS1 First Name(s):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "a262ae1fa4314d1aa183b87aa57ce796"
                  )[0]?.answer ?? "",
                "AS1 Surname:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "9f438b22fd0941e7af5a504737f19889"
                  )[0]?.answer ?? "",
                "AS1 Date of Birth:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "54bcaae7e3834bff9779c3e9d1a424db"
                  )[0]?.answer ?? "",
                "AS1 Ghana Card Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "cd42ee891cd0469ebf77389f9a162b91"
                  )[0]?.answer ?? "",
                "AS1 Secondary Contact Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "0d01988c208546439e55000796194558"
                  )[0]?.answer ?? "",
                "AS1 Primary Contact Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "aa6ffdb1ebd04c59bccb8f766343e9ef"
                  )[0]?.answer ?? "",
                "AS1 Email Address:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "238b8427a67b4f6d963a17adbb94cca7"
                  )[0]?.answer ?? "",
                "AS1 Job Title:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "cb480142d07347ddbc8db9c64943ae06"
                  )[0]?.answer ?? "",
                "AS1 Ghana Card (FRONT:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "532ebe56d81c4fa0be892d35d7f010bd"
                  )[0]?.answer ?? "",
                "AS1 Ghana Card (BACK):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "292909bee320419b8b3644b12ce76868"
                  )[0]?.answer ?? "",
                //
                "AS2 First Name(s):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "ea4e6e14224249379d70df6bb81095d1"
                  )[0]?.answer ?? "",
                "AS2 Surname:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "b0e74099089648b89e88527a3b09d1cc"
                  )[0]?.answer ?? "",
                "AS2 Date of Birth:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "549f5fdf730646c1acee2fc0615f4527"
                  )[0]?.answer ?? "",
                "AS2 Ghana Card Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "bee57a584f6d456aa3a8ce50a8ce6b1f"
                  )[0]?.answer ?? "",
                "AS2 Primary Contact Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "d4d1d02991e24ca0b04cb3958dd51b50"
                  )[0]?.answer ?? "",
                "AS2 Secondary Contact Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "fdeb2be3982040508ad5c1977498cf13"
                  )[0]?.answer ?? "",
                "AS2 Job Title:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "42a691477f7148549f5dee3fec916ac7"
                  )[0]?.answer ?? "",
                "AS2 Email Address:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "94616a7bc4524e5097c7f86f57b464f6"
                  )[0]?.answer ?? "",
                "AS2 Ghana Card (BACK):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "5cb6cf5d7b52408ba59927625322dd32"
                  )[0]?.answer ?? "",
                "AS2 Ghana Card (FRONT:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "403b33783e75455a956320fb014d923d"
                  )[0]?.answer ?? "",
                //
                "AS3 First Name(s):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "191afd9cdf5741e2afe49c5bca58deea"
                  )[0]?.answer ?? "",
                "AS3 Surname:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "a7171a3cb9774ae1bae2212cbde92b05"
                  )[0]?.answer ?? "",
                "AS3 Date of Birth:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "d0d7a97ba2584174b502a5dadb1c277f"
                  )[0]?.answer ?? "",
                "AS3 Ghana Card Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "1b19c2e13ddb44459bcdc8babe7f4769"
                  )[0]?.answer ?? "",
                "AS3 Email Address:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "0715b59f397345c68cff86444c6eb4e3"
                  )[0]?.answer ?? "",
                "AS3 Primary Contact Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "e123719617c54b76b2545c90b7d0b1b6"
                  )[0]?.answer ?? "",
                "AS3 Secondary Contact Number:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "dbf4b33c77eb42f5822766dfc715d809"
                  )[0]?.answer ?? "",
                "AS3 Job Title:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "e3b011c2b3e644428954cf5525b8fab4"
                  )[0]?.answer ?? "",
                "AS3 Ghana Card (BACK):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "8407926b4b814a069cd3775093dfc985"
                  )[0]?.answer ?? "",
                "AS3 Ghana Card (FRONT:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "f709364d1e9c40bcaebe250717a76160"
                  )[0]?.answer ?? "",
                //
                "Primary Signatory (A):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "88e7796c6f654ad4b28c5e8f70ea9f12"
                  )[0]?.answer ?? "",
                "Primary Signatory (B):":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "5de1858b0306485eb662ec8f89896560"
                  )[0]?.answer ?? "",
                "Select Account Mandate":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "dd5debf0755749f2bd9cbaf9f8feec1f"
                  )[0]?.answer ?? "",
                "Primary Signatory (C:":
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "6701d6b50dab4a9ca91a899405918710"
                  )[0]?.answer ?? "",
              },
              field_boolean_data: {},
              field_date_data: {},
              field_radio_data: {},
            },
            actions: [
              {
                recipient_name:
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "a262ae1fa4314d1aa183b87aa57ce796"
                  )[0]?.answer ?? "",
                recipient_email:
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "238b8427a67b4f6d963a17adbb94cca7"
                  )[0]?.answer ?? "",
                action_id: "401738000000043610",
                signing_order: 1,
                verify_recipient: false,
                private_notes: "",
              },
              {
                recipient_name:
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "ea4e6e14224249379d70df6bb81095d1"
                  )[0]?.answer ?? "",
                recipient_email:
                  String(
                    answers?.filter(
                      (x) =>
                        x?.questionIdentifier ===
                        "94616a7bc4524e5097c7f86f57b464f6"
                    )[0]?.answer
                  ).toLocaleLowerCase() ?? "",
                action_id: "401738000000043610",
                signing_order: 1,
                verify_recipient: false,
                private_notes: "",
              },
              {
                recipient_name:
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "191afd9cdf5741e2afe49c5bca58deea"
                  )[0]?.answer ?? "",
                recipient_email:
                  answers?.filter(
                    (x) =>
                      x?.questionIdentifier ===
                      "0715b59f397345c68cff86444c6eb4e3"
                  )[0]?.answer ?? "",
                action_id: "401738000000043610",
                signing_order: 1,
                verify_recipient: false,
                private_notes: "",
              },
            ],
            notes: "",
          },
        },
        {
          headers: {
            Authorization:
              "Zoho-oauthtoken 1000.IIKDBBMQV4ZRVR3JLBZY4YY7MDTZ5M.2a55ef0a30c5f26fba531700003beeb647bfae399c",
          },
        }
      )
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setloading(false);
      });
    // axiosInstance
    //   .put(BASE_API + "/kyc/" + kycid + "/submit", {})
    //   .then((res) => {
    //     console.log(res.data);
    //     if (res?.data?.kycStatus === "SUBMITTED") {
    //       // send document sign request
    //       axios.post(
    //         "https://sign.zoho.com/api/v1/templates/401738000000042721/createdocument",
    //         {
    //           templates: {
    //             field_data: {
    //               field_text_data: {
    //                 templates: {
    //                   field_data: {
    //                     field_text_data: {
    //                       "Business Name:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "4d4cc62861074484b34d661ae48c28c0"
    //                         )[0]?.answer ?? "",
    //                       "Date:": new Date().toLocaleDateString(),
    //                       "Industry:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "efa1d2fa939248068ed64677106601a9"
    //                         )[0]?.answer ?? "",
    //                       "Category of Business:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "55b51e0e7a634d42a1ce8b47908d0c8b"
    //                         )[0]?.answer ?? "",
    //                       "Coporate Email Address:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "b1bcf0e5ff1e4431a4e96dfa4699d772"
    //                         )[0]?.answer ?? "",
    //                       "Corporate Website:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "49ffa09d610b4557b67f0e1360c91aae"
    //                         )[0]?.answer ?? "",
    //                       "Digial Address (Ghana Post GPS):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "7ba6aec43d534f959dfca5257495da17"
    //                         )[0]?.answer ?? "",
    //                       "Country:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "8c3ea3ee930c4b5289e22d9c5707062a"
    //                         )[0]?.answer ?? "",
    //                       "Company Phone Number 2:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "f5219fe9930e4996a85c102dd435f339"
    //                         )[0]?.answer ?? "",
    //                       "Company Phone Number 1:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "700a1ea307fa4790841296f52b42bbd9"
    //                         )[0]?.answer ?? "",
    //                       "Certificate ofincorporation Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "f39711dc74ee4ba481dfd66a89026da9"
    //                         )[0]?.answer ?? "",
    //                       "Date of incorporation:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "e93087e41b2c420dbf10e43f456b9ea9"
    //                         )[0]?.answer ?? "",
    //                       "TIN/Ghana Card Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "4281a743842740a099440d99d0bcc864"
    //                         )[0]?.answer ?? "",
    //                       "Source of Funds:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "b7ba67994e1b42759f14d90388e527b8"
    //                         )[0]?.answer ?? "",
    //                       "Expected initial invesment (GHS:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "ec19ab20e3934287a43707eef68a9f4b"
    //                         )[0]?.answer ?? "",
    //                       "Expected Top-Ups:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "eb14b36370e0440a821600574a7d6e24"
    //                         )[0]?.answer ?? "",
    //                       "Expected withdrawals:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "e4c3958c8f444cb8b2d64a729c7bbc4a"
    //                         )[0]?.answer ?? "",
    //                       "Contact Person First Name(s):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "e2b345c92edf44f3bd0d2fb68e20dc60"
    //                         )[0]?.answer ?? "",
    //                       "Contact Person  Surname:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "41ea96d3c8e641fbb99ffb7b293359bd"
    //                         )[0]?.answer ?? "",
    //                       "Contact Person  Date of Birth:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "2bef8a05c0d04b90a4275f1b2a9a42a0"
    //                         )[0]?.answer ?? "",
    //                       "Contact Person  Ghana Card Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "26ae92072cd443b7aa920ed4f69415a6"
    //                         )[0]?.answer ?? "",
    //                       "Contact Person Contact Number 1:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "0a2846128da34af3a447d330caffeb95"
    //                         )[0]?.answer ?? "",
    //                       "Contact Person Contact Number 2:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "e446662d33a74e9987d40f4e0a8daf25"
    //                         )[0]?.answer ?? "",
    //                       "Contact Person  Residential Status:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "4b699be35ffa4ec68a52ea2976a26bf5"
    //                         )[0]?.answer ?? "",
    //                       "Contact Person Resident Permit Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "d353cc88858e448b9611a28b7d376e69"
    //                         )[0]?.answer ?? "",
    //                       "Contact Person Job Title:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "09ab5093ad1e40c487017f3379aaa775"
    //                         )[0]?.answer ?? "",
    //                       "Bank Name":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "f933d6afc1704bdaa46dfd27ca6e250e"
    //                         )[0]?.answer ?? "",
    //                       "Account Name:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "0acfd716e3a243bb9a9c735c0c293e90"
    //                         )[0]?.answer ?? "",
    //                       "Bank Sort Code:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "2a8f30a05680407e81320a20217c8bec"
    //                         )[0]?.answer ?? "",
    //                       "Account Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "adaa30db9eb7435990b2c82862e9ae06"
    //                         )[0]?.answer ?? "",
    //                       "Director 1 First Name(s):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "ec921cd2b8a14d61b2d4bd3269562cd2"
    //                         )[0]?.answer ?? "",
    //                       "Director 1 Surname:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "3686a89a6f6f498e907630d18d3351e4"
    //                         )[0]?.answer ?? "",
    //                       "Director 1 Ghana Card Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "22d91821cb3a4dc8baec4186f3c26266"
    //                         )[0]?.answer ?? "",
    //                       "Director 1: IsThis Director Politically Exposed:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "429b5b7ab54842718e69e569017c49dd"
    //                         )[0]?.answer ?? "",
    //                       "Director 1 Contact Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "ef6938225c724a0ab8aa8c7c972fdf7c"
    //                         )[0]?.answer ?? "",
    //                       "Director 1 Passport-Sized Photo":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "0cfd7149545444928589ee2e9d6b5e6e"
    //                         )[0]?.answer ?? "",
    //                       "Director 1 Ghana Card (Back):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "96cb719fe0bd47a1831d27266134013f"
    //                         )[0]?.answer ?? "",
    //                       "Director 1 Ghana Card (Front):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "5be4f65b3afc4c998fb72cbe413ce283"
    //                         )[0]?.answer ?? "",
    //                       //
    //                       "Director 2 First Name(s):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "4580519cdf764fd89754344fc47bf836"
    //                         )[0]?.answer ?? "",
    //                       "Director 2  Surname:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "da16046f679445048f7dfd2d187ca7e7"
    //                         )[0]?.answer ?? "",
    //                       "Director 2 : IsThis Director Politically Exposed:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "b38335dae2184b43a433824687edb78a"
    //                         )[0]?.answer ?? "",
    //                       "Director 2  Ghana Card Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "a84a4916f622449fb2ef62f2d2c1a19b"
    //                         )[0]?.answer ?? "",
    //                       "Director 2  Contact Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "7f7bdca1881e43288961d06d57ec2d8b"
    //                         )[0]?.answer ?? "",
    //                       "Director 2 Passport-Sized Photo":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "75e10352975046dc9aa89117f2b7eca7"
    //                         )[0]?.answer ?? "",
    //                       "Director 2  Ghana Card (Back):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "82de93854f7e4335b59deed67a013b8c"
    //                         )[0]?.answer ?? "",
    //                       "Director 2  Ghana Card (Front:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "b129d7b85fbe417c91ea102f14830217"
    //                         )[0]?.answer ?? "",
    //                       //
    //                       "Director 3 First Name(s):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "0f3341d273ee4df097676441240187a3"
    //                         )[0]?.answer ?? "",
    //                       "Director 3 Surname:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "158e8fa286a5427a83dbc82c570cd884"
    //                         )[0]?.answer ?? "",
    //                       "Director 3 Ghana Card Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "e609a446d4244387991a28882feddef6"
    //                         )[0]?.answer ?? "",
    //                       "Director 3: IsThis Director Politically Exposed:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "aacf99d6f3d94a30a1880dfbebfc8726"
    //                         )[0]?.answer ?? "",
    //                       "Director 3 Contact Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "05335d7ea229457aa64c1469fee43af2"
    //                         )[0]?.answer ?? "",
    //                       "Director 3 Passport-Sized Photo":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "a1a1204e835c4cecb5b817c0a8a3c919"
    //                         )[0]?.answer ?? "",
    //                       "Director 3 Ghana Card (Front):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "a9413434e8d84d59862528b4cb890ce2"
    //                         )[0]?.answer ?? "",
    //                       "Director 3 Ghana Card (Back):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "d4986861b04645aaa1d624005b4588c3"
    //                         )[0]?.answer ?? "",
    //                       //
    //                       "BO First Name(s):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "1972eb69465d4548b732f133d8889bbb"
    //                         )[0]?.answer ?? "",
    //                       "BO  Surname:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "1ddc527251b64eb9b08619cfddf11975"
    //                         )[0]?.answer ?? "",
    //                       "BO  Date Of Birth:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "104393e53f09434ea81630f76860e59f"
    //                         )[0]?.answer ?? "",
    //                       "BO  Ghana Card Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "2370d0b0f47449a696f247be436ddfb3"
    //                         )[0]?.answer ?? "",
    //                       //
    //                       "BO2 First Name(s):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "3a67ce53d5394240b06c5c740fcc50a2"
    //                         )[0]?.answer ?? "",
    //                       "BO2 Surname:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "91ad67d4705b4cec90a19af1e05b281a"
    //                         )[0]?.answer ?? "",
    //                       "BO2 Ghana Card Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "0ff1f6c22f3a4b849709650bcdff0107"
    //                         )[0]?.answer ?? "",
    //                       "BO2 Date Of Birth:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "12153549a0b749f6961379d7e138559b"
    //                         )[0]?.answer ?? "",
    //                       //
    //                       "AS1 First Name(s):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "a262ae1fa4314d1aa183b87aa57ce796"
    //                         )[0]?.answer ?? "",
    //                       "AS1 Surname:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "9f438b22fd0941e7af5a504737f19889"
    //                         )[0]?.answer ?? "",
    //                       "AS1 Date of Birth:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "54bcaae7e3834bff9779c3e9d1a424db"
    //                         )[0]?.answer ?? "",
    //                       "AS1 Ghana Card Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "cd42ee891cd0469ebf77389f9a162b91"
    //                         )[0]?.answer ?? "",
    //                       "AS1 Secondary Contact Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "0d01988c208546439e55000796194558"
    //                         )[0]?.answer ?? "",
    //                       "AS1 Primary Contact Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "aa6ffdb1ebd04c59bccb8f766343e9ef"
    //                         )[0]?.answer ?? "",
    //                       "AS1 Email Address:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "238b8427a67b4f6d963a17adbb94cca7"
    //                         )[0]?.answer ?? "",
    //                       "AS1 Job Title:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "cb480142d07347ddbc8db9c64943ae06"
    //                         )[0]?.answer ?? "",
    //                       "AS1 Ghana Card (FRONT:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "532ebe56d81c4fa0be892d35d7f010bd"
    //                         )[0]?.answer ?? "",
    //                       "AS1 Ghana Card (BACK):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "292909bee320419b8b3644b12ce76868"
    //                         )[0]?.answer ?? "",
    //                       //
    //                       "AS2 First Name(s):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "ea4e6e14224249379d70df6bb81095d1"
    //                         )[0]?.answer ?? "",
    //                       "AS2 Surname:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "b0e74099089648b89e88527a3b09d1cc"
    //                         )[0]?.answer ?? "",
    //                       "AS2 Date of Birth:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "549f5fdf730646c1acee2fc0615f4527"
    //                         )[0]?.answer ?? "",
    //                       "AS2 Ghana Card Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "bee57a584f6d456aa3a8ce50a8ce6b1f"
    //                         )[0]?.answer ?? "",
    //                       "AS2 Primary Contact Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "d4d1d02991e24ca0b04cb3958dd51b50"
    //                         )[0]?.answer ?? "",
    //                       "AS2 Secondary Contact Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "fdeb2be3982040508ad5c1977498cf13"
    //                         )[0]?.answer ?? "",
    //                       "AS2 Job Title:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "42a691477f7148549f5dee3fec916ac7"
    //                         )[0]?.answer ?? "",
    //                       "AS2 Email Address:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "94616a7bc4524e5097c7f86f57b464f6"
    //                         )[0]?.answer ?? "",
    //                       "AS2 Ghana Card (BACK):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "5cb6cf5d7b52408ba59927625322dd32"
    //                         )[0]?.answer ?? "",
    //                       "AS2 Ghana Card (FRONT:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "403b33783e75455a956320fb014d923d"
    //                         )[0]?.answer ?? "",
    //                       //
    //                       "AS3 First Name(s):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "191afd9cdf5741e2afe49c5bca58deea"
    //                         )[0]?.answer ?? "",
    //                       "AS3 Surname:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "a7171a3cb9774ae1bae2212cbde92b05"
    //                         )[0]?.answer ?? "",
    //                       "AS3 Date of Birth:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "d0d7a97ba2584174b502a5dadb1c277f"
    //                         )[0]?.answer ?? "",
    //                       "AS3 Ghana Card Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "1b19c2e13ddb44459bcdc8babe7f4769"
    //                         )[0]?.answer ?? "",
    //                       "AS3 Email Address:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "0715b59f397345c68cff86444c6eb4e3"
    //                         )[0]?.answer ?? "",
    //                       "AS3 Primary Contact Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "e123719617c54b76b2545c90b7d0b1b6"
    //                         )[0]?.answer ?? "",
    //                       "AS3 Secondary Contact Number:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "dbf4b33c77eb42f5822766dfc715d809"
    //                         )[0]?.answer ?? "",
    //                       "AS3 Job Title:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "e3b011c2b3e644428954cf5525b8fab4"
    //                         )[0]?.answer ?? "",
    //                       "AS3 Ghana Card (BACK):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "8407926b4b814a069cd3775093dfc985"
    //                         )[0]?.answer ?? "",
    //                       "AS3 Ghana Card (FRONT:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "f709364d1e9c40bcaebe250717a76160"
    //                         )[0]?.answer ?? "",
    //                       //
    //                       "Primary Signatory (A):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "88e7796c6f654ad4b28c5e8f70ea9f12"
    //                         )[0]?.answer ?? "",
    //                       "Primary Signatory (B):":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "5de1858b0306485eb662ec8f89896560"
    //                         )[0]?.answer ?? "",
    //                       "Select Account Mandate":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "dd5debf0755749f2bd9cbaf9f8feec1f"
    //                         )[0]?.answer ?? "",
    //                       "Primary Signatory (C:":
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "6701d6b50dab4a9ca91a899405918710"
    //                         )[0]?.answer ?? "",
    //                     },
    //                     field_boolean_data: {},
    //                     field_date_data: {},
    //                     field_radio_data: {},
    //                   },
    //                   actions: [
    //                     {
    //                       recipient_name:
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "a262ae1fa4314d1aa183b87aa57ce796"
    //                         )[0]?.answer ?? "",
    //                       recipient_email:
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "238b8427a67b4f6d963a17adbb94cca7"
    //                         )[0]?.answer ?? "",
    //                       action_id: "401738000000043610",
    //                       signing_order: 1,
    //                       verify_recipient: false,
    //                       private_notes: "",
    //                     },
    //                     {
    //                       recipient_name:
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "ea4e6e14224249379d70df6bb81095d1"
    //                         )[0]?.answer ?? "",
    //                       recipient_email:
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "94616a7bc4524e5097c7f86f57b464f6"
    //                         )[0]?.answer ?? "",
    //                       action_id: "401738000000043610",
    //                       signing_order: 1,
    //                       verify_recipient: false,
    //                       private_notes: "",
    //                     },
    //                     {
    //                       recipient_name:
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "191afd9cdf5741e2afe49c5bca58deea"
    //                         )[0]?.answer ?? "",
    //                       recipient_email:
    //                         answers?.filter(
    //                           (x) =>
    //                             x?.questionIdentifier ===
    //                             "0715b59f397345c68cff86444c6eb4e3"
    //                         )[0]?.answer ?? "",
    //                       action_id: "401738000000043610",
    //                       signing_order: 1,
    //                       verify_recipient: false,
    //                       private_notes: "",
    //                     },
    //                   ],
    //                   notes: "",
    //                 },
    //               },
    //               field_boolean_data: {},
    //               field_date_data: {},
    //               field_radio_data: {},
    //             },
    //             actions: [
    //               {
    //                 recipient_name:
    //                   answers?.filter(
    //                     (x) =>
    //                       x?.questionIdentifier ===
    //                       "a262ae1fa4314d1aa183b87aa57ce796"
    //                   )[0]?.answer ?? "",
    //                 recipient_email:
    //                   answers?.filter(
    //                     (x) =>
    //                       x?.questionIdentifier ===
    //                       "238b8427a67b4f6d963a17adbb94cca7"
    //                   )[0]?.answer ?? "",
    //                 action_id: "401738000000042724",
    //                 signing_order: 1,
    //                 verify_recipient: false,
    //                 private_notes: "",
    //               },
    //               {
    //                 recipient_name:
    //                   answers?.filter(
    //                     (x) =>
    //                       x?.questionIdentifier ===
    //                       "ea4e6e14224249379d70df6bb81095d1"
    //                   )[0]?.answer ?? "",
    //                 recipient_email:
    //                   answers?.filter(
    //                     (x) =>
    //                       x?.questionIdentifier ===
    //                       "94616a7bc4524e5097c7f86f57b464f6"
    //                   )[0]?.answer ?? "",
    //                 action_id: "401738000000042724",
    //                 signing_order: 1,
    //                 verify_recipient: false,
    //                 private_notes: "",
    //               },
    //               {
    //                 recipient_name:
    //                   answers?.filter(
    //                     (x) =>
    //                       x?.questionIdentifier ===
    //                       "191afd9cdf5741e2afe49c5bca58deea"
    //                   )[0]?.answer ?? "",
    //                 recipient_email:
    //                   answers?.filter(
    //                     (x) =>
    //                       x?.questionIdentifier ===
    //                       "0715b59f397345c68cff86444c6eb4e3"
    //                   )[0]?.answer ?? "",
    //                 action_id: "401738000000042724",
    //                 signing_order: 1,
    //                 verify_recipient: false,
    //                 private_notes: "",
    //               },
    //             ],
    //             notes: "",
    //           },
    //         },
    //         {
    //           headers: {
    //             Authorization:
    //               "Zoho-oauthtoken 1000.e99d65b181d7ae68c74352e486887410.d9ea10c1ab5c5a1737b24b4fba5d5850",
    //           },
    //         }
    //       );
    //       // window?.location?.reload();
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .finally(() => {
    //     setloading(false);
    //   });
  };
  return (
    <div className="disclaimer">
      <h1>Disclaimer for Corporate Account (KYC) at Black Star Group Ltd</h1>
      <div className="box" ref={scrollRef}>
        <p>
          <strong>Introduction</strong>
        </p>
        <p>
          By opening an account with Black Star Group Ltd (&quot;BSG&quot;), you
          (&quot;the Client&quot;) acknowledge and agree to the terms and
          conditions set forth in this disclaimer. Black Star Group Ltd includes
          its subsidiaries, Black Star Advisors (Asset Management and Advisory)
          and Black Star Brokerage. This document outlines the responsibilities,
          obligations, and limitations associated with the corporate account
          opening process and the Know Your Customer (KYC) requirements.
        </p>
        <p>
          <strong>KYC and AML Compliance</strong>
        </p>
        <p>
          Black Star Group Ltd is committed to complying with all applicable
          laws and regulations regarding Anti-Money Laundering (AML) and
          Counter-Terrorist Financing (CTF). As part of this commitment, BSG
          requires all clients to provide comprehensive information and
          documentation to establish their identity, the nature of their
          business, and the source of funds. Failure to provide the necessary
          documentation may result in delays, limitations, or the inability to
          open or maintain an account.
        </p>
        <p>
          <strong>Information Collection</strong>
        </p>
        <p>
          The Client agrees to provide accurate, complete, and up-to-date
          information as required by BSG&#39;s KYC procedures. This information
          may include, but is not limited to, corporate documents,
          identification of beneficial owners, authorized signatories, financial
          statements, and other relevant information. BSG reserves the right to
          verify the information provided and to request additional
          documentation or information as deemed necessary.
        </p>
        <p>
          <strong>Data Protection</strong>
        </p>
        <p>
          BSG is committed to protecting the privacy and confidentiality of the
          Client&#39;s information. All information provided for KYC purposes
          will be handled in accordance with BSG&#39;s privacy policy and
          applicable data protection laws. The Client&#39;s information will be
          used solely for the purposes of complying with regulatory requirements
          and managing the Client&#39;s account.
        </p>
        <p>
          <strong>Third-Party Verification</strong>
        </p>
        <p>
          To fulfill its KYC obligations, BSG may use third-party service
          providers to verify the Client&#39;s information. By agreeing to this
          disclaimer, the Client consents to the use of such third-party
          services for verification purposes. BSG ensures that all third-party
          service providers are subject to confidentiality obligations and
          comply with applicable data protection regulations.
        </p>
        <p>
          <strong>Ongoing Monitoring</strong>
        </p>
        <p>
          The Client acknowledges that BSG will conduct ongoing monitoring of
          the account to ensure continued compliance with AML and CTF
          regulations. This may include periodic reviews of the Client&#39;s
          transactions, updated KYC information, and additional requests for
          documentation. The Client agrees to promptly provide any requested
          information or documentation to facilitate this ongoing monitoring
          process.
        </p>
        <p>
          <strong>Account Restrictions and Termination</strong>
        </p>
        <p>
          BSG reserves the right to impose restrictions on the account or
          terminate the account if the Client fails to comply with the KYC
          requirements or if there are reasonable grounds to suspect involvement
          in money laundering, terrorist financing, or other illegal activities.
          In such cases, BSG may be required to report the activity to relevant
          authorities without notifying the Client.
        </p>
        <p>
          <strong>Limitation of Liability</strong>
        </p>
        <p>
          BSG, its subsidiaries, directors, officers, employees, and agents
          shall not be liable for any direct, indirect, incidental, or
          consequential damages arising from the Client&#39;s failure to comply
          with KYC requirements or from the actions taken by BSG to comply with
          its legal and regulatory obligations. The Client agrees to indemnify
          and hold harmless BSG from any claims, losses, or damages resulting
          from their non-compliance with KYC requirements.
        </p>
        <p>
          <strong>Governing Law</strong>
        </p>
        <p>
          This disclaimer shall be governed by and construed in accordance with
          the laws of the jurisdiction in which BSG operates. Any disputes
          arising from or related to this disclaimer shall be subject to the
          exclusive jurisdiction of the courts of that jurisdiction.
        </p>
        <p>
          <strong>Acceptance</strong>
        </p>
        <p>
          By proceeding with the account opening process, the Client
          acknowledges that they have read, understood, and agreed to the terms
          and conditions outlined in this disclaimer. The Client&#39;s
          acceptance of these terms is a prerequisite for the successful opening
          and maintenance of their account with BSG.
        </p>
        <hr />
        <p>
          <strong>Contact Information</strong>
        </p>
        <p>
          For any questions or concerns regarding this disclaimer or the KYC
          process, please contact our compliance department at
          compliance@blackstargroup.com or +123-456-7890.
        </p>
        <hr />
        <p>
          This disclaimer is intended to ensure transparency and mutual
          understanding between Black Star Group Ltd and its clients. It is
          essential for maintaining a secure and compliant financial environment
          for all parties involved.
        </p>
      </div>
      <div className="">
        <input type="checkbox" /> I agree to the terms and conditions
      </div>
      <div className="button_container3">
        <button
          disabled={loading || !enable}
          onClick={handleSubmit}
          className="btn btn-primary"
          type="submit"
        >
          {loading ? "Loading..." : "Submit"}
        </button>
      </div>
    </div>
  );
};
