import React, { useState, useRef, useCallback } from "react";
import Webcam from "react-webcam";
import axios from "axios";
import { BASE_API } from "../api/axios";

export const CameraGroup = ({
  label = "",
  value,
  onChange,
  kycid,
  identifier,
  required,
  x,
  type,
}) => {
  const [loading, setLoading] = useState(false);
  const [showWebcam, setShowWebcam] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const webcamRef = useRef(null);

  // Capture Image from Webcam
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    setShowWebcam(false);
  }, [webcamRef]);

  // Upload the captured image (Webcam Only)
  const handleImageUpload = async () => {
    if (capturedImage) {
      setLoading(true);
      try {
        // Convert base64 image to Blob
        const blob = await fetch(capturedImage).then((res) => res.blob());
        const formData = new FormData();
        formData.append("files[0].file", blob, "webcam-image.png");
        formData.append("files[0].identifier", identifier);

        const response = await axios.post(
          `${BASE_API}/kyc/${kycid}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          console.log("Image uploaded successfully");
          onChange(response.data["webcam-image.png"]);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Remove Uploaded Image
  const handleFileRemove = async () => {
    try {
      const response = await axios.delete(
        `${BASE_API}/kyc/${kycid}/document/${value}/delete`
      );
      if (response.status === 200) {
        console.log("File removed successfully");
        onChange(null);
      }
    } catch (error) {
      console.error("Error removing file:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="input-contain3">
      <label>
        {label} <span style={{ color: "Red" }}>{required && "*"}</span>
      </label>
      <span>
        {x?.description} ({type})
      </span>
      {value ? (
        <div className="file-container">
          <div>
            <div className="file-name">File Uploaded</div>
            <div className="file-size">{value}</div>
          </div>
          <div className="file-remove">
            <button onClick={handleFileRemove}>Remove</button>
          </div>
        </div>
      ) : (
        <>
          {/* <button onClick={() => setShowWebcam(true)}>Open Camera</button> */}
          <div onClick={() => setShowWebcam(true)} className="file-container">
            <div
              style={{
                padding: 6.5,
              }}
            >
              <div className="file-name">Open Camera</div>
            </div>
          </div>

          {showWebcam && (
            <div style={styles.modalOverlay}>
              <div style={styles.modal}>
                <div
                  style={{
                    color: "#000",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={400}
                    height={400}
                  />
                  <div
                    style={{
                      paddingTop: 10,
                    }}
                  >
                    <button
                      style={{
                        marginRight: 10,
                      }}
                      onClick={capture}
                    >
                      Capture Photo
                    </button>
                    <button onClick={() => setShowWebcam(false)}>
                      Close Camera
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {capturedImage && (
            <div
              style={{
                color: "#fff",
              }}
            >
              <img
                src={capturedImage}
                alt="Captured"
                style={{ width: "320px", height: "240px" }}
              />
              <button onClick={handleImageUpload}>
                {loading ? "Uploading..." : "Save Photo"}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const styles = {
  container: {
    textAlign: "left",
    width: "49%",
  },
  openButton: {
    width: "100%",
    height: "45px",
    border: "1px solid #58595b7d",
    borderRadius: "7px",
    fontSize: "15px",
    fontFamily: "Outfit-Regular, sans-serif",
    fontStyle: "normal",
    textTransform: "capitalize",
    textDecoration: "none",
    color: "#fff",
    marginBottom: "5px",
    background: "transparent",
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    backgroundColor: "#1d1d1d7d",
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    width: "auto",
    height: "auto",
    textAlign: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  sigContainer: {
    marginBottom: "20px",
    // height: 300,
  },
  buttons: {
    margin: "5px",
    padding: "10px 20px",
    backgroundColor: "#000000",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  sigPad: {
    border: "1px solid #ccc",
    width: "100%",
    height: 300,
  },
  sigImage: {
    marginTop: "20px",
    border: "1px solid #ccc",
    maxWidth: "100%",
    background: "#fff",
  },
};
